import React from 'react'
import './AboutStyles.css'

import collage from '../../assests/Collage.png'

import sachin from '../../assests/sachin.jpeg'
import neelam from '../../assests/neelam.jpg'
import ad1 from '../../assests/ad1.jpg'
import ad2 from '../../assests/ad2.jpg'
import img2 from '../../assests/img2.PNG'
const About = () => {
  return (
    <div>
      <div className='about'>
        <div className='company'>
          <img src={collage} />
          <div className='introduction'>
            <p>Greenoearth Pvt. Ltd. aim to develop sustainable healthcare solutions and wants to change how India sees healthcare in the future. We plan to make healthcare innovations and products which is people centric and have least burden on the environment.</p>
            <p>We develop healthcare goods that can be reused and , are environmentally sustainable, and can either degrade naturally or be recycled effectively. These products are not disposable or made of unsustainable materials.</p>
            <p>We developed and made available the following eco-friendly healthcare goods : </p>
            <p>1. Anti-pollution mask is designed with lightweight and smooth fabric with 5 layer filtration based nano technology.</p>
            <p>2. The Sanitizer Machine which can create a very secure and natural sanitizer from water and readily available materials.</p>
            <p>3. We created "green stations," which would include safe organic sanitizer manufacturing and dispensing facilities as well as pure drinking water with all minerals present.</p>
            <p>4. We provide a complete indoor laboratory facility for research and education in multi-agent systems, flight robotics, flight control, and aerospace control</p>
          </div>
        </div>

        <h1>MEET OUR TEAM</h1>
        <div className='team'>
          <div className='member'>
            <img src={sachin} />
            <p>Sachin Arora as the Director of Product and Business Development with a Bachelor's degree in Mechanical Engineering and a specialization in Air and Environmental Quality Management, is a dynamic professional with six years of diverse experience in the fields of product development and business development.
              he has proven instrumental in driving sales and forging strategic partnerships. He is recognized as an expert in Indoor Air Quality (IAQ), holding certifications such as Pure Health Global Certified IAQ Engineer.
            </p>
          </div>
          <div className='member'>
            <img src={neelam} />
            <p>Dr. Neelam Rathore is a highly accomplished and dedicated professional with a Ph.D. in Material Sciences from the prestigious Indian Institute of Technology (IIT) Bombay. With over six years of extensive experience in the field of research and development (R&D), she has emerged as a prominent figure in her domain.</p>
          </div>
        </div>

        <h1>OUR FACULTY ADVISOR</h1>
        <div className='advisor'>
          <div className='faculty'>
            <img src={ad1} />
            <h3>Dr. V K Jain</h3>
            <p>Advisor</p>
            <p>Scientist & Professor Amity Institute for Advanced Research and Studies (Materials & Devices)</p>
          </div>
          <div className='faculty IMG_NEW'>
            <img src={img2} />
            <h3>Dr Ram Prakash</h3>
            <p>Advisor</p>
            <p>(Ph. D, M.SC., B. SC) Developing a Cold Plasma Laboratory at IIT Jodhpur, Plasma physics awarded in 2002 </p>
          </div>
          <div className='faculty'>
            <img src={ad2} />
            <h3>Dr. I B Singh</h3>
            <p>Advisor</p>
            <p>( M.Sc.,Ph.D.,BHU) FDAAD,FSAEST,MIIM, MMRSI Senior Principal Scientist, CSIR Professor </p>
          </div>
        </div>


      </div>
    </div>
  )
}

export default About