import React , {useEffect,useState} from 'react'
import './SlideStyles.css'

function Slide() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const container = document.querySelector('.slid-container');
      const height = container.clientHeight;
      const maxScrollTop = container.scrollHeight - height;
      const newScrollPosition = scrollPosition + height;
      // console.log(maxScrollTop,newScrollPosition)

      if (newScrollPosition > maxScrollTop) {
        container.scrollTo({ top: 0, behavior: 'smooth' });
        setScrollPosition(0);
      } else {
        container.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
        setScrollPosition(newScrollPosition);
      }
    }, 1500);

    return () => clearInterval(interval);
  }, [scrollPosition]);
  
  return (
    <div className='slid'>
        <div className='slid-container'>
            <div className='slid-one'></div>    
            <div className='slid-two'></div>  
            <div className='slid-three'></div>  
            <div className='slid-four'></div>  
            <div className='slid-five'></div>  
        </div>
    </div>
  )
}

export default Slide