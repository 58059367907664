import React from 'react'
import './ServicesStyles.css'

import p1 from '../../assests/p1.jpg'
import p2 from '../../assests/p2.jpg'
import p3 from '../../assests/p3.jpg'

const Services = () => {
  return (
    <div className='services'>   
        <div className='container1'>
          <img src={p1} />
          <h3>SUSTAINABLE HEALTHCARE PRODUCTS</h3>
          <div className='overlay'>
          <h3>SUSTAINABLE HEALTHCARE PRODUCTS</h3>
            <p>Our sustainability strategy promotes positive impact on patients, community, public, and planet. We prioritize well-being, employee development, community support, and environmental conservation. Our healthcare solutions include sanitizer machines, air purifiers, AIRI masks, and green stations.</p>
          </div>
        </div>
        <div className='container1'>
          <img src={p2} />
          <h3>CREATING DRONE & IOT LABS</h3>
          <div className='overlay'>
          <h3>CREATING DRONE & IOT LABS</h3>
            <p>We provide a complete indoor lab facility for research and education in drones, robotics, and aerospace control. Our goal is to offer students a deep learning platform to understand the elements and principles of drones. Drone Labs scales up educational opportunities for interested students.</p>
          </div>
        </div>
        <div className='container1'>
          <img src={p3} />
          <h3>INDUSTRIAL<br/> TRAINING</h3>
          <div className='overlay'>
          <h3>INDUSTRIAL TRAINING</h3>
            <p>Nowadays, Degrees alone don't ensure employment. Graduates need current information, practical experience, soft skills, and dedication to succeed in the job market. Industrial Training in the curriculum enhances employability by building critical skills.</p>
          </div>
        </div>
    </div>
  )
}

export default Services