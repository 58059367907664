import React from 'react'

import Navbar from './components/navbar/Navbar'
import Main from './components/main/Main'
import Gallery from './components/gallery/Gallery'
import Services from './components/services/Services'
import Footer from './components/footer/Footer'
import Product from './components/products/Product'
import Certificate from './components/certificate/Certificate'
import Achievements from './components/achivements/Achievements'

function App() {
  return (
    <div>
      <Navbar/>
      <Main/>
      <Services/>
      <Product/>
      <Achievements/>
      {/* <Certificate/> */}
      <Gallery/>
      <Footer/>
    </div>
  )
}

export default App