import React, { useState } from 'react'
import './NavbarStyles.css'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import greenOearth from '../../assests/gree.png'

const Navbar = () => {
    const [nav, setNav] = useState(0)
    return (
        <header className='navbar'>
            <img src={greenOearth} alt='/' />
            <nav>
                <ul className={nav ? 'menu active' : 'menu'}>
                    <li><Link to='/'>HOME</Link></li>
                    <li><Link to='/explore'>EXPLORE</Link></li>
                    <li><Link to='/about'>ABOUT US</Link></li>
                    <li><Link to='/contact'>CONTACT</Link></li>
                </ul>
            </nav>
            <div onClick={() => setNav(!nav)} className='bars'>
                {nav ? <FaTimes size={25} /> : <FaBars size={25} />}

            </div>
        </header>
    )
}

export default Navbar