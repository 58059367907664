import React from 'react'
import './SliderStyles.css'

import Mycard from '../mycard/Mycard'

import mask from '../../assests/mask.jpeg'
import mask1 from '../../assests/mask1.jpeg'
import mask2 from '../../assests/mask2.jpeg'
import mask3 from '../../assests/mask3.jpeg'
import mask4 from '../../assests/mask4.jpeg'

function Slider() {
  const leftbtn = () => {
    const box = document.querySelector('.product-container');
    if (box) {
      const width = box.clientWidth;
      if (box.scrollLeft > 0) {
        box.scrollLeft -= width;
      } else {
        box.scrollLeft = box.scrollWidth;
      }
    }
  };

  const rightbtn = () => {
    const box = document.querySelector('.product-container');
    if (box) {
      const width = box.clientWidth;
      if (box.scrollLeft < box.scrollWidth - width) {
        box.scrollLeft += width;
      } else {
        box.scrollLeft = 0;
      }
    }
  };
  
  
  return (
    <div className='product-carousel'>
        <button className='left-btn' onClick={leftbtn}><i>&lt;</i></button>
        <button className='right-btn' onClick={rightbtn}><i>&gt;</i></button>
    <div className='product-container'>
   <Mycard cardno='1' img= {mask4}/>
   <Mycard cardno='2' img= {mask1} />
   <Mycard cardno='3' img={mask}/>
   <Mycard cardno='4' img={mask2}/>
   <Mycard cardno='5' img={mask3}/>
   <Mycard cardno='6' img={mask1}/>
   </div> 
    </div>
    
  )
}

export default Slider