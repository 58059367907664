import React from 'react'
import {Link} from 'react-router-dom'
import './MainStyles.css'

const Main = () => {

    return (
        <div className='main'>
            <div className='cont'>
                <div><span>Greenoearth Pvt. Ltd.</span> aims to develop sustainable healthcare solutions and wants to change how India sees healthcare in the future. We plan to make healthcare innovations and products which is people centric and have least burden on the environment</div>
                <div><h2><u>Our Vision</u></h2><p>Our vision is to bring a revolution in the healthcare sector in India and worldwide, where a sustainable approach to healthcare is promoted and the products designed ensure ecological and environmental sustainability.</p></div>
                <div><h2><u>Our Mission</u></h2><p>Our mission is to create healthcare products that are either of the use-and-throw variety or not produced  from unsustainable materials, but the products which have long and multiple usage, is environmentally sustainable and could be either decomposed naturally or recycled efficiently.</p>
                   </div>
                   <Link to='/about'><button className='btn'>More About Us</button></Link>
            </div>
     </div>   
    )
}

export default Main