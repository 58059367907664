import React from 'react'
import'./MycardStyles.css'

function Mycard(props) {
  let className ;
  if(props.cardno==='1'){
     className= 'carousel-text1'
  }
  else
    className='carousel-text'
  const style={
    '--product-image-url':`url(${props.img})`,

  }
  return (
    <div className='carousel'>
      <div className={className} style={style}>
        
        </div>   
    </div>
    
  )
}

export default Mycard