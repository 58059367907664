import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import './SanitizerStyles.css'
import Certificate from '../certificate/Certificate';
import s7 from '../../assests/s7.jpg'
import img2 from '../../assests/front2.jpeg'

import Popup from '../popup/Popup'
import Video from '../video/Video'
import Slider from '../slider/Slider'
import Mask from '../mask/Mask'
import Drone from '../drone/Drone'
import Greenstation from '../greenstation/Greenstation'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
function Sanitizer() {
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
      if (state && state.from === 'product' && state.section) {

        const targetSection = document.getElementById(state.section);
        console.log(targetSection)
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [state]);
    const handleClick = (event) => {
        setbutton(true);
        const mouseX = event.pageX;
        const mouseY = event.pageY;
        setMouseX(mouseX)
        setMouseY(mouseY)
   
      };
    
    const style = {
        '--product-image-url': `url(${s7})`, 
        '--product1-image-url':`url(${img2})`
      };
      const sectionRef=useRef(null);
      const maskSectionRef = useRef(null);
      const greenstationSectionRef = useRef(null);
      const droneSectionRef = useRef(null);
      const sanitRef=useRef(null);
      const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
    const [button,setbutton]=useState(false)
    const handleClick1 = () => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };
    return (
<>
<Navbar/>

    <div className= 'sanitizer'>
    <div className='sanitizer-container' id="sanit" ref={sanitRef} >
        <div className='sanitizer-top one-sanitizer-top'>
            <div className='sanitizer-top-detail'>
            <h1 className='sanitizer-head'>Sanitizer machine</h1>
            <p className='sanitizer-para'>Its a Eco-friendly & Organic Disinfectant ,Non Toxic and Non-Hazardous Sanitizer-Machine</p>
            <button className='sanitizer-btn' onClick={handleClick1}>Know More</button>
            </div>
        </div>
        <div className='sanitizer-detail one-top'>
            <div className='sanitizer-detail-detail'>
                <h2 className='sanitizer-detail-heading' >Why You Should Purchase This</h2>
                <p className='sanitizer-detail-paragraph'>
                It is non-toxic, non-hazardous, and can be used in hand sanitizer, cloth and fabric cleaner. In fact, it is also suitable for cleaning baby and pet toys. It's safe for eyes, as no harsh chemicals are used in this sanitizer. Its only made from salt and water mixture
                </p>

            </div>
        </div>
    </div>
    <section ref={sectionRef} className='about-sanitizer'>
        {/* <h1 className='about-head'>Our Product</h1> */}
        <div className='about-flex'>
            <div className='about-one first'  >
            <div className='about-product'>
                <div className='about-productimg zero-img' style={style}>
                
                </div>
               
                <div className='about-product-detail'>
                    <div className='abt'>
                    <h1 className='about-product-head'>Affordable Sanitizer Solution</h1>
                    <p className='about-product-para'>Just INR 2/Litre! One-time investment for long-lasting protection and savings</p>
                    <button className='sanitizer-btn' onClick={handleClick}>Buy Now</button>
                    </div>
                </div>


            </div>
            </div>
            <div className='about-one second' >
            <div className='about-product'>
                <div className='about-productimg one-img' style={style}>
                
                </div>
              
                <div className='about-product-detail'>
                    <div className='abt1'>
                    <h1 className='about-product-head'>Introducing our Organic Sanitizer</h1>
                    <p className='about-product-para'>No harsh chemicals, non-flammable, providing safe and effective protection</p>
                    <button className='sanitizer-btn' onClick={handleClick} >Buy Now</button>
                    </div>
                </div>


            </div>  
            </div>
            <div className='about-one third' >
            <div className='about-product'>
                <div className='about-productimg two-img' style={style}>
                
                </div>
              
                <div className='about-product-detail'>
                   <div className='abt2'>
                    <h1 className='about-product-head'>Water and salt-based sanitizer</h1>
                    <p className='about-product-para'>Safe, effective, non-toxic alternative to chemical sanitizers. Simple yet powerful protection</p>
                    <button className='sanitizer-btn' onClick={handleClick}>Buy Now</button>
                    </div> 
                </div>


            </div>  
            </div>
           
        </div>
    </section>
    <section className='diff'>
        <div className='diff-one'>
            <div className='txt'>
                <h2 className='head-txt'>How It Is Different From Other Product</h2>
            </div>
        </div>
        <div className='diff-one'>
            <div className='txt2'>
                <h2 className='head-txt2'>Environment Friendly</h2>
                <p className='para'>It is safe to sanitize public places</p>
            </div>
        </div>
        <div className='diff-one '>
        <div className='txt3'>
                <h2 className='head-txt3'>Usage</h2>
                <p className='para'>It is used for Hand Sanitizer,Fruits and Vegetable wash, Baby & Pet Toys Cleaner , Cloth and Fabric Cleaner</p>
            </div>
        </div>
        <div className='diff-one'>
        <div className='txt4'>
                <h2 className='head-txt4'>Cost Effective</h2>
                <p className='para'>It is priced at just ₹2 per liter, and once purchased, you won't need to buy it again </p>
            </div>
        </div>
    </section>
    
   <Video/>
   <Certificate/>
   
   <section className='mask-sec' id="mask" ref={maskSectionRef} >
   <Mask/>
   <Slider/>

   </section >
   <Greenstation  id="greenstation" ref={greenstationSectionRef} />
   <Drone id="drone" ref={droneSectionRef} />
    </div>
     <Popup trigger={button} setTrigger={setbutton} mousex={mouseX} mousey={mouseY} ></Popup>
     <Footer/>
     </>
  )
}

export default Sanitizer;
