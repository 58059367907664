import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Contact from '../components/contact/Contact'
import Footer from '../components/footer/Footer'

const ContactPage = () => {
  return (
    <>
    <Navbar/>
    <Contact/>
    <Footer/>
    </>
  )
}

export default ContactPage