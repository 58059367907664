import React from 'react'

import vid from '../../assests/vid.mp4'
import './VideoStyles.css'

function video() {
  // const style = {
  //     '--product-image-url': `url(${img1})`, // Set the value of the custom property
  //     '--product1-image-url':`url(${img2})`
  //   }
  return (
    <div className='vid-con'>
      <div className="video-container">
        <video controls className="background-video">
          <source src={vid} type="video/mp4" />
        </video>
      </div>
      <div className="content">
        <h1 className='content-heading'>Easy and Affordable DIY Sanitizer</h1>
        <p className='content-paragraph'>Create your own sanitizer at home using a simple water and salt mixture. This DIY solution is easy to make and provides effective disinfection. Stay germ-free with this budget-friendly sanitizer.</p>
      </div>
    </div>

  )
}

export default video