import React from 'react';
import { BrowserRouter ,Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import AboutPage from './routes/AboutPage';
import ContactPage from './routes/ContactPage';
import ExplorePage from './routes/ExplorePage';
import Drone from './components/drone/Drone';
import Sanitizer from './components/sanitizer/Sanitizer';
import Mask from './components/mask/Mask';
import Greenstation from './components/greenstation/Greenstation';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/explore' element={<ExplorePage/>}/>
      <Route path='/about' element={<AboutPage />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/drone' element={<Drone/>}/>
      <Route path='/sanitizer' element={<Sanitizer/>}/>
      <Route path='/mask' element={<Mask/>}/>
      <Route path='/greenstation' element={<Greenstation/>}/>

    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);