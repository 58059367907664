import React from 'react'
import './ContactStyles.css'
import Gallery from '../gallery/Gallery'

const Contact = () => {
  return (
    <div className='contact'>
      <div className='address'>
        <div className='telephone'>
          <h3>ADDRESS</h3>
          <p>UG-3, A-32, Khasra No 130,</p>
          <p>IGNOU Main Rd, Neb Sarai,  New Delhi, Delhi 110068</p>
          <br/>
          <p>TISC IIT Jodhpur INH 62, Surpura Bypass Rd,</p>
          <p> Karwar, Rajasthan 342030</p>
        </div>
        <div className='telephone'>
          <h3>TELEPHONE : 9971530934</h3>
          <h3>Website : www.greenoearth.com</h3>

          <h3><a href = "mailto: abc@example.com" style={{textDecoration: 'none',color:'black'}}>info@greenoearth.com</a></h3>
        </div>
      </div>
      <Gallery/>
    </div>
  )
}

export default Contact