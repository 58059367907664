// import React from 'react'
import React, { useRef, useState, useEffect } from 'react';
import './MaskStyles.css'

import mask from '../../assests/mask.jpeg'
import mask1 from '../../assests/mask1.jpeg'
import mask2 from '../../assests/mask2.jpeg'
import Popup from '../popup/Popup'
function Mask() {
    const handleClick = (event) => {
        setbutton(true);
        const mouseX = event.pageX;
        const mouseY = event.pageY;
        setMouseX(mouseX)
        setMouseY(mouseY)
   
      };
      const [mouseX, setMouseX] = useState(0);
      const [mouseY, setMouseY] = useState(0);
    const [button,setbutton]=useState(false);
    const style = {
        '--product-image-url': `url(${mask})`,
        '--product1-image-url': `url(${mask1})`,
        '--product2-image-url': `url(${mask2})`

    }
    return (
        <div className='mask-top'>
            <div className='marq'>
                <marquee scrollamount="20" style={{ direction: 'ltr' }}><h1>AIRI Mask</h1></marquee>
            </div>
            <div className='mask-flex'>
                <div className='flex-container one-mask'>
                    <div className='mask-text'>
                        <h1 className='mask-head'>AIRI Mask </h1>
                        <p className='mask-para'>Introducing the Pial anti-pollution mask, a game-changer with its revolutionary design and 5-layer nano technology filtration. Lab-tested for over 80 hours of protection against pollutants like PM 2.5 & PM 10, dust, and emissions. Comfortable, breathable, and stylish for everyday use during activities. Non-washable yet highly durable for long-lasting performance. Stay protected and breathe easy with Pial.</p>
                        <button className='mask-btn' onClick={handleClick}>Buy Now</button>
                    </div>
                </div>
                <div className='flex-container two'>
                    <div className='one-div' style={style}></div>
                    <div className='two-div' style={style}></div>
                    <div className='three-div' style={style}></div>
                </div>
                
            </div>
            <Popup trigger={button} setTrigger={setbutton} mousex={mouseX} mousey={mouseY} ></Popup>
        </div>
    )
}

export default Mask