import React from 'react'

import Navbar from '../components/navbar/Navbar'
import About from '../components/about/About'
import Footer from '../components/footer/Footer'

const AboutPage = () => {
  return (
    <>
    <Navbar/>
    <About/>
    <Footer/>
    </>
  )
}

export default AboutPage