import React from 'react'
import './FooterStyles.css'

function Footer() {
    return (
        <div className='footer'>
            <div className='info'>
                <div className='col'>
                    <h3>Explore our Products</h3>
                    <p className='data'>Sanitizer Machine</p>
                    <p className='data'>Green Station</p>
                    <p className='data'>AIRI Mask</p>
                    <p className='data'>Agricultural Drone</p>
                </div>
                <div className='col'>
                    <h3>Get to know us</h3>
                    <p className='data'>About us</p>
                    <p className='data'>Contact</p>
                </div>
                <div className='col'>
                    <h3>Address</h3>
                    <p>UG-3, A-32, Khasra No 130,</p>
                    <p>IGNOU Main Rd, Neb Sarai,  New Delhi, Delhi 110068</p>
                    <br/>
                    <p>TISC IIT Jodhpur INH 62, Surpura Bypass Rd,</p>
                    <p> Karwar, Rajasthan 342030</p>
                </div>
            </div>
        </div>
    )
}

export default Footer