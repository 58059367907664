import React from 'react'
import './ProductStyles.css'
import { Link } from 'react-router-dom'

import s2 from '../../assests/s2.jpg'
import s7 from '../../assests/s7.jpg'
import s9 from '../../assests/s9.jpg'
import s12 from '../../assests/s12.png'

const Product = () => {
    return (
        <div className='product'>
            <div className='heading'>
                <h2>Sustainable Healthcare Products</h2>
            </div>
            <div className='product-type'>
                <div className='for'>
                    <img src={s7} />
                    <Link to={{ pathname: '/sanitizer', state: { from: 'product', section: 'sanit' } }}><button className='btn1'>Sanitizer Machine</button></Link>
                </div>
                <div className='for'>
                    <img src={s12} />
                    <Link to={{ pathname: '/sanitizer', state: { from: 'product', section: 'mask' } }}><button className='btn1'>AIRI Mask</button></Link>
                </div>
                <div className='for'>
                    <img src={s9} />
                    <Link to={{ pathname: '/sanitizer', state: { from: 'product', section: 'greenstation' } }}><button className='btn1'>GreenStation</button></Link>
                </div>
                <div className='for'>
                    <img src={s2} />
                    <Link to={{ pathname: '/sanitizer', state: { from: 'product', section: 'drone' } }}><button className='btn1'>Agriculture Drone</button></Link>
                </div>
            </div>
        </div>
    )
}

export default Product