import React from 'react'
import'./Greenstation.css'

import Slide from '../slide/Slide'

function Greenstation() {
  return (
    <>
     <div className='marq_greenstation'>
                <marquee scrollamount="20" style={{ direction: 'ltr' }}><h1>Greenstation</h1></marquee>
     </div>
    
    <div className='Green'>
    
    <div className='green-flex'>
        <div className='one-Green'>
         <Slide/>
        </div>
        <div className='two-Green'>
            <div className='green-text'>
            <h1 className='Green-head'>Greenstation</h1>
              <h1 className='green-head'> Revolutionary Hypochlorous Acid Solution</h1>
              <p className='green-para'>100x Better, Easy to Use, Non-Toxic, Multi-Surface, Cost-Effective. Ideal for Sanitizing Any Surface, from Offices to Open Areas</p>
            </div>
        </div>
       
        
    </div>
    </div>
    </>
  )
}

export default Greenstation