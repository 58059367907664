import React from 'react'
import './DroneStyles.css'

import s2 from '../../assests/s2.jpg';
import drone from '../../assests/s3.jpg';
import s1 from '../../assests/s1.jpg';
import drone3 from '../../assests/circle.jpg';

function Drone() {
  return (
    <>
      <div className='marq_drone'>
                <marquee scrollamount="20" style={{ direction: 'ltr' }}><h1>Drone</h1></marquee>
            </div>
    <div className='drone-flex'>
     
        <div className='second-drone'>
            <div className='drone-text'>
                <h3 className='drone-head'>Unleash the Potential of Drones with our Educational Drone Lab Setup</h3>
                <p className='drone-para'>Discover the world of drones through our educational Drone Lab Setup. Engage in deep learning, exploring drone fundamentals, working principles, and fabrication procedures. Empowering students with hands-on experiences</p>
            </div>

        </div>
        <div className='first-drone'>
            <div className='anim'>
                <div className='img-one First-one'>
                <img src={drone} /> 
                </div>
                <div className='img-one Second-one'>
                <img src={s1} /> 
                </div>
                <div className='img-one third-one'>
                <img src={s2} /> 
                </div>
                <div className='img-one fourth-one'>
                <img src={drone3} /> 
                </div>
            {/* <div className='anim-img'>
                <img src={droneImage} />
                <img src={droneImage} />
                <img src={droneImage} />
                <img src={droneImage} />
                <img src={droneImage} />
                <img src={droneImage} />
                
            </div> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default Drone