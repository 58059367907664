import React from 'react'
import video from "../../assests/video.mp4"
import "./Video.css"
function Video() {
  return (
    <div className="flex-itm3">
    {[10].map((num, index) => (
      <div
        key={num}
        className={`box2 flex-${num}`}
      >
      <video src={video}  controls></video>
      </div>
    ))}
    <div className='slide_vid_box box2 flex-11'>
      <h1 className='slide_vid_box_head'>Pre Launched By</h1>
      <h3 className='slide_vid_box_heading'> Vice president, Shri venkaiah Naidu at IIT Jodhpur </h3>
    </div>
     
       

  </div>
);
}
export default Video