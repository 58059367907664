import React from 'react'
import './AchiveStyles.css'

import a1 from '../../assests/a1.png'
import a2 from '../../assests/a2.png'
import a3 from '../../assests/a3.png'
import a4 from '../../assests/a4.png'


const Achievements = () => {
  return (
    <div className='achieve'>
        <div><img src={a1}/></div>
        <div><img src={a2}/></div>
        <div><img src={a3}/></div>
        <div><img src={a4}/></div>
    </div>
  )
}

export default Achievements




