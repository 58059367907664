import React, { useEffect } from 'react';
import './PopupStyles.css'

// import gif from '../images/Gif.gif'
import { FaTimes } from 'react-icons/fa'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Popup(props) {
  const popupClassName = props.trigger ? 'popup open' : 'popup closed';
  useEffect(() => {
    if (props.trigger) {
      // console.log('hellooooooooo');
      const OPEN = document.getElementsByClassName("popup")[0];
      OPEN.style.top = `${props.mousey}px`;
      OPEN.style.left = '50%';
      // console.log(OPEN.style.top)
    }
  }, [props.trigger, props.mousex, props.mousey]);
  

  

  return (props.trigger) ? (


    <div className={popupClassName} >


      <div className='popup-btn'>

        <button className="close-button" onClick={() => props.setTrigger(false)}>
        <FaTimes />
        </button>

        <div className='popup-text'>
          <h3 className='popup-heading'>Online Order Not Started Yet</h3>


          <a href="tel:9971530934" className='tel'>9971530934</a>
        </div>


      </div>
    </div>
  ) : "";
}

export default Popup