import React from 'react'
import './GalleryStyles.css'

import s1 from '../../assests/s1.jpg'
import s2 from '../../assests/s2.jpg'
import s3 from '../../assests/s3.jpg'
import s4 from '../../assests/s4.jpg'
import s5 from '../../assests/s5.jpg'
import s6 from '../../assests/s6.jpg'
import s7 from '../../assests/s7.jpg'
import s8 from '../../assests/s8.jpg'
import s9 from '../../assests/s9.jpg'
import mask1 from '../../assests/mask1.jpeg'
import mask2 from '../../assests/mask1.jpeg'

function Gallery() {
  return (
    <div class="slider">
      <div class="slider-track">
        <img src={s1} />
        <img src={mask1}/>
        <img src={s2} />
        <img src={s9}/>
        <img src={s3} />
        <img src={s7} />
        <img src={s8} />
        <img src={s4} />
        <img src={mask2}/>
        <img src={s5} />
        <img src={s6}/>  

      </div>

      <div class="slider-track">
        <img src={s1} />
        <img src={mask1}/>
        <img src={s2} />
        <img src={s3} />
        <img src={s4} />
        <img src={mask2}/>
        <img src={s5} />
        <img src={s6}/>
        <img src={s7} />
        <img src={s8} />
        <img src={s9}/>
      </div>
    </div>

  )
}

export default Gallery