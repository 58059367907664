import React, { useState } from 'react';
import './Report.css';
import result1 from "../../assests/result1.png"
import result2 from "../../assests/result2.png"
import result3 from "../../assests/result3.png"
import video from "../../assests/video.mp4"
const productImages = [result1, result2, result3];
function Report() {
  const [hoverStates, setHoverStates] = useState([false, false, false,false]);
  // const style={
  //   '--product-image-url':`url(${result1})`,
  //   '--product-image-url2':`url(${result2})`,
  //   '--product-image-url3':`url(${result3})`
  // }
  const handleViewMoreEnter = (index) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = true;
    setHoverStates(newHoverStates);
  };

  const handleViewMoreLeave = (index) => {
    const newHoverStates = [...hoverStates];
    newHoverStates[index] = false;
    setHoverStates(newHoverStates);
  };

  return (
    <div className="flex-itm2">
      {[7,8,9].map((num, index) => (
        <div
          key={num}
          className={`box1 flex-${num}`}
          style={{ '--product-image-url': `url(${productImages[index]})` }}
          onMouseEnter={() => handleViewMoreEnter(index)}
          onMouseLeave={() => handleViewMoreLeave(index)}
        >
        
          {hoverStates[index] && (
            <div className="hover-element">
              <button className='btn_btn'>View More</button>
            </div>
          )}
        </div>
      ))}
       
         

    </div>
  );
}

export default Report;
