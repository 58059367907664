import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Sanitizer from '../components/sanitizer/Sanitizer'
import Footer from '../components/footer/Footer'

const ExplorePage = () => {
  return (
    <>
    {/* <Navbar/> */}
    <Sanitizer/>
    {/* <Footer/> */}
    </>
  )
}

export default ExplorePage