import React, { useState } from 'react';
// import './style.css';
import "./certificate.css"
import All from "../ALL/All";
import Report from "../reports/Report";
import Video from "../one_vid/Video";
const Certificate = () => {
  const [showOne, setShowOne] = useState(true);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);


  const handleOneClick = () => {
    setShowOne(true);
    setShowTwo(false);
    setShowThree(false);
  };

  const handleTwoClick = () => {
    setShowOne(false);
    setShowTwo(true);
    setShowThree(false);
  };

  const handleThreeClick = () => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(true);
  };


  return (
    <section className="work">
      {/* <h1 className="workhead">Approved</h1> */}
      <div className="flex-cls">
        <div className='one flx' onClick={handleOneClick}>
          <h3>All</h3>
        </div>
        <div className='two flx' onClick={handleTwoClick}>
          <h3>Certificates</h3>
        </div>
        <div className="three flx" onClick={handleThreeClick}>
          <h3>Videos</h3>
        </div>
      </div>
      {showOne && (
            <All/>
      )}
      {showTwo && (
       <Report/>
      )}
      {showThree && (
       <Video/>
      )}
    </section>
  );
};

export default Certificate;
